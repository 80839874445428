  <template>
  <div>
    <el-dialog :title="text+'讲义'"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               :validate-on-rule-change="false"
               ref="formRefs"
               v-if="dialogVisible"
               label-width="80px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="讲义名称"
                          prop="lecture_name">
              <el-input clearable
                        placeholder="请输入"
                        v-model="form.lecture_name"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="讲义类别"
                          prop="lecture_category_id">
              <el-select v-model="form.lecture_category_id"
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in categoryList"
                           :key="item.lecture_category_id"
                           :label="item.lecture_category_name"
                           :value="item.lecture_category_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="上传文件"
                          prop="filename">
              <el-button type="primary"
                         v-if="!form.filename"
                         @click="checkFile">
                选择文件
              </el-button>
              <el-tag closable
                      v-else
                      @close="clear">
                {{form.filename}}
              </el-tag>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="讲义描述">
              <el-input clearable
                        placeholder="请输入"
                        v-model="form.lecture_description"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="排序">
              <el-input-number clearable
                               placeholder="请输入"
                               v-model="form.sort"
                               :precision='0'
                               :min="0"
                               style="width:100%;"
                               controls-position="right" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10"
                v-if="text=='新增'">
          <el-col :span="24">
            <el-form-item label="科目:"
                          prop="subject_id">
              <el-select v-model="form.subject_id"
                         style="width:100%"
                         @change="subjectChangeGetSelects"
                         placeholder="请选择">
                <el-option v-for="item in subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="考点:"
                          prop="knowledge_id">
              <div class="zhishidian">
                <el-cascader v-model="form.knowledge_id"
                             style="width:100%"
                             @change="changeknowledge"
                             :options="knowledgeList"
                             :props="knowledgeProps"></el-cascader>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="题目编码">
              <el-input clearable
                        placeholder="请输入"
                        v-model="form.lecture_questions"
                        style="width:100%" />
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  class="hint">
            说明：多道题目是以半角分号分隔的题目编码
          </el-col>
        </el-row>

      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="handleSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getUserSubject } from '@/api/subject.js'
import { getLevel12Knowledge } from '@/api/knowledge.js'
export default {
  data () {
    return {
      dialogVisible: false,
      text: '新增',
      form: {},
      file: null,
      rules: {
        lecture_name: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        lecture_category_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        filename: [
          { required: true, message: '请选择', trigger: 'change' },
        ]
      },
      treeList: [],
      categoryList: [],
      subjectList: [],
      knowledgeProps: {
        multiple: true,
        checkStrictly: true,
        children: 'child',
        value: 'knowledge_id',
        label: 'knowledge_desc',
      },
      flatKnowledge: {},
      knowledgeList: [],
      copyKnowledge: [],
    }
  },
  methods: {
    async parentComponentEvent (form) {
      const { data } = await getLevel12Knowledge({ subject_id: this.form.subject_id })
      this.knowledgeList = data.list


      this.flatKnowledge = {}


      // 查找考点完整路径代码块 开始
      var knowledgeList = this.flatList(JSON.parse(JSON.stringify(this.knowledgeList)))
      knowledgeList.forEach(item => {
        this.flatKnowledge[item.knowledge_id] = item
      });
      var knoIds = []
      // 组建完整的考点路径
      form.knowledge_id.forEach(item => {
        var obj = this.flatKnowledge[item]
        if (obj.knowledge_parent_id && this.flatKnowledge[obj.knowledge_parent_id].child) {
          knoIds.push(this.searchParentKnowledge(obj))
        } else {
          knoIds.push([item])
        }
      })
      form.knowledge_id = knoIds


    },
    flatList (tree) {
      var allList = []
      for (let index = 0; index < tree.length; index++) {
        allList.push(tree[index])
        if (tree[index]['child'] && tree[index]['child'].length) {
          allList = [...allList, ...this.flatList(tree[index]['child'])]
        }
      }
      return allList;
    },
    searchParentKnowledge (obj) {
      var arr = []
      // 查找父级 往数组前插入
      arr.unshift(obj.knowledge_id)
      if (obj.knowledge_parent_id) {
        arr = [...this.searchParentKnowledge(this.flatKnowledge[obj.knowledge_parent_id]), ...arr]
      }
      return arr
    },
    changeknowledge (value) {
      // value 是选中的 cascader 选项的完整路径

      this.copyKnowledge = []
      if (value && value.length > 0) {
        value.forEach(item => {
          this.copyKnowledge.push(item[item.length - 1]);
        });
      }
      // 使用 Set 进行去重
      this.copyKnowledge = [...new Set(this.copyKnowledge)];

    },
    async getSubjectList () {
      const { data } = await getUserSubject()
      this.subjectList = data.list
    },
    subjectChangeGetSelects () {
      this.form.knowledge_id = []
      if (!this.form.subject_id) {
        return
      }

      this.getKnowledge()
    },
    async getKnowledge () {
      const { data } = await getLevel12Knowledge({ subject_id: this.form.subject_id })
      this.knowledgeList = data.list
    },
    clear () {
      this.file = null
      this.$set(this.form, 'filename', '')
      console.log(this.form.filename)
    },
    handleClose () {
      this.form = {}
      this.$refs.formRefs.resetFields()
      this.dialogVisible = false
    },
    handleSave () {
      this.$refs.formRefs.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))


        let formdata = new FormData();
        if (form.filename) {
          form.filename = 'pdf'
          formdata.append('pdf', this.file)
        } else {
          delete form.filename
        }
        if (form.knowledge_id) {
    
          this.copyKnowledge.forEach((item) => {
            formdata.append('knowledge_ids[]', item);
          });
        }

        delete form.knowledge_id
        for (const key in form) {
          formdata.append(key, form[key])
        }
        formdata.append('sign', this.$getMd5Form(form))


        let url = this.text == '新增' ? '/api/v1/lecture/create' : '/api/v1/lecture/edit'

        this.$http({
          url,
          method: 'POST',
          data: formdata
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.$parent.refresh()
          this.handleClose()
        })
      })
    },
    checkFile () {
      this.$get_file({
        limit: 50,
        fileType: '.pdf'
      }).then(res => {
        this.form.filename = res.name
        this.file = res
      })
    },
    getCategoryList () {
      this.$http({
        url: '/api/v1/lecture/category',
        method: 'get',
        params: {
          page: 1,
          limit: 100000
        }
      }).then(res => {
        this.categoryList = res.data.list
      })
    },
    delFileRole () {
      this.rules.filename = []
    },
    addFileRole () {
      this.rules.filename = [
        { required: true, message: '请选择', trigger: 'change' },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.tree_warp {
  height: 350px;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.hint {
  font-size: 14px;
}
::v-deep .el-input-number.is-controls-right .el-input__inner {
  text-align: left;
}
</style>